import React, {useEffect, useState} from 'react';
import {Button, Form, Input, InputNumber, Modal, Popconfirm, Space, Table, Upload} from 'antd';
import {supabase} from './supabase.js';
import {useNavigate, useParams} from 'react-router-dom';
import {UploadOutlined} from '@ant-design/icons';
import {v4} from 'uuid';
import {BUCKET_ID, TABLE_PACKAGES} from './supabase';


const ApplicationsFormModal = ({form, open, onOk, onCancel, customRequest}) => {
    const package_id = form.getFieldValue('id');
    return (
        <Modal
            open={open}
            title='应用'
            okText='提交'
            cancelText='取消'
            okButtonProps={{autoFocus: true}}
            onCancel={onCancel}
            destroyOnClose
            onOk={onOk}
        >
            <Form layout='vertical' form={form}>
                <Form.Item name='id' label='包标识' hidden={true}>
                    <Input/>
                </Form.Item>
                <Form.Item name='channel_id' label='渠道标识' hidden={true}>
                    <Input/>
                </Form.Item>
                <Form.Item label='文件' hidden={package_id !== null && package_id !== undefined}>
                    <Upload maxCount={1} customRequest={customRequest}>
                        <Button icon={<UploadOutlined/>}>上传</Button>
                    </Upload>
                </Form.Item>
                <Form.Item name='version_code' label='版本代码'>
                    <InputNumber size="large"/>
                </Form.Item>
                <Form.Item name='version_name' label='版本名称'>
                    <Input/>
                </Form.Item>
                <Form.Item name='changelog' label='日志'>
                    <Input/>
                </Form.Item>
                <Form.Item name='package_name' label='包名'>
                    <Input/>
                </Form.Item>
                <Form.Item name='icon' label='图标'>
                    <Input/>
                </Form.Item>
                <Form.Item name='download_url' label='下载链接'>
                    <Input disabled={true}/>
                </Form.Item>
                <Form.Item name='bucket_path' label='桶' hidden={true}>
                    <Input disabled={true}/>
                </Form.Item>

            </Form>
        </Modal>
    );
};


const Packages = () => {
    const navigate = useNavigate();
    // const [isNew, setIsNew] = useState(true);
    const [data, setData] = useState([]);
    const [open, setOpen] = useState(false);
    const [form] = Form.useForm();
    const [modal, contextHolder] = Modal.useModal();


    const params = useParams()
    const channel_id = params['channel_id'];
    form.setFieldsValue(params)

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        const {data, error} = await supabase.from(TABLE_PACKAGES)
            .select()
            .eq('channel_id', channel_id);
        if (error) {
            console.log(error)
            modal.error({
                title: error.message,
                content: error.hint,
            });
            return
        }
        if (data) {
            console.log(data)
            data.sort((a, b) => a.id - b.id)
            setData(data);
        }
    };
    const onBackClick = () => {
        navigate(-1)
    }
    const onNewClick = () => {
        console.log('onNewClick')
        // setIsNew(true)
        form.resetFields()
        setOpen(true)
    }
    const onEditClick = (record) => {
        console.log('onEditClick')
        console.log(record)
        // setIsNew(false)
        form.setFieldsValue(record)
        setOpen(true);
    }

    const onCancelClick = async () => {
        console.log('onCancelClick')
        form.resetFields()
        setOpen(false)
    }
    const onOkClick = async () => {
        console.log('onOkClick')
        const value = form.getFieldsValue()
        console.log(value)

        const {data, error} = await supabase.from(TABLE_PACKAGES)
            .upsert(value)
            .select()
        if (error) {
            console.log(error)
            modal.error({
                title: error.message,
                content: error.hint,
            });
            return
        }
        if (data) {
            console.log(data)
        }

        setOpen(false)
        form.resetFields()
        fetchData()
    }


    const onDeleteClick = async (record) => {
        console.log(record)
        const package_id = record['id']
        console.log(package_id)
        const {data, error} = await supabase.from(TABLE_PACKAGES)
            .delete()
            .eq('id', package_id);
        if (error) {
            console.log(error)
            modal.error({
                title: error.message,
                content: error.hint,
            });
            return
        }
        if (data) {
            console.log(data)
        }
        fetchData()
    }
    const customRequest = async (option) => {
        const file = option.file
        console.log(file)
        const fileExtension = file.name.split('.').pop().toLowerCase();

        const {data, error} = await supabase
            .storage
            .from(BUCKET_ID)
            .upload(`${v4()}.${fileExtension}`, file)
        if (error) {
            console.log(error)
            modal.error({
                title: error.message,
                content: error.hint,
            });
            return
        }
        if (data) {
            console.log(data)
            const bucket_path = data.path;
            const download_url = supabase.storage.from(BUCKET_ID).getPublicUrl(bucket_path).data.publicUrl;
            option.onSuccess(bucket_path)
            form.setFieldsValue({
                'bucket_path': bucket_path,
                'download_url': download_url,
            })
        }
    }


    const columns = [
        {
            title: '包标识',
            key: 'id',
            dataIndex: 'id',
            align: 'center',
            width: 100,
        },
        {
            title: '包名',
            key: 'package_name',
            dataIndex: 'package_name',
            align: 'center',
            width: 200,
        },
        {
            title: '版本代码',
            key: 'version_code',
            dataIndex: 'version_code',
            align: 'center',
            width: 100,
        },
        {
            title: '版本名称',
            key: 'version_name',
            dataIndex: 'version_name',
            align: 'center',
            width: 200,
        },
        {
            title: '图标',
            key: 'icon',
            dataIndex: 'icon',
            align: 'center',
            width: 100,
        },
        {
            title: '下载链接',
            key: 'download_url',
            dataIndex: 'download_url',
            align: 'center',
            width: 300,
            ellipsis: true,
        },
        {
            title: '日志',
            key: 'changelog',
            dataIndex: 'changelog',
            align: 'center',
        },
        {
            title: '操作',
            key: 'action',
            align: 'center',
            width: 300,
            render: (_, record) => (
                <Space size='middle'>
                    <Button onClick={() => onEditClick(record)}>编辑</Button>
                    <Popconfirm
                        title='删除包'
                        description='您确定要删除该包吗？'
                        onConfirm={() => onDeleteClick(record)}
                        onCancel={null}
                        okText='是'
                        cancelText='否'
                    >
                        <Button danger>删除</Button>
                    </Popconfirm>
                </Space>
            ),
        }
    ]

    return (
        <div>
            <div style={{margin: '20px'}}>
                <div style={{display: 'flex', justifyContent: 'space-between'}}>
                    <Button type='default' variant='default' onClick={onBackClick}
                            style={{marginBottom: '20px'}}>返回上一页</Button>
                    <header>包管理</header>
                    <div style={{display: 'flex', alignItems: 'center'}}>
                        <Button type='default' variant='default' onClick={fetchData}
                                style={{marginBottom: '20px', marginRight: '10px'}}>刷新</Button>
                        <Button type='primary' variant='primary' onClick={onNewClick}
                                style={{marginBottom: '20px'}}>添加</Button>
                    </div>
                </div>
                <ApplicationsFormModal
                    form={form}
                    open={open}
                    onOk={onOkClick}
                    onCancel={onCancelClick}
                    customRequest={customRequest}
                />
                {contextHolder}
                <Table columns={columns} dataSource={data}/>
            </div>
        </div>
    );
}

export default Packages;
