// App.js

import React from 'react';
import {BrowserRouter, Route, Routes} from 'react-router-dom';
import Login from './Login';
import Applications from "./Applications";
import Channels from "./Channels";
import Packages from "./Packages";
import Devices from "./Devices";

const App = () => {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<Login/>}/>
                <Route path="/applications" element={<Applications/>}/>
                <Route path="/channels/:application_id" element={<Channels/>}/>
                <Route path="/packages/:channel_id" element={<Packages/>}/>
                <Route path="/devices/:channel_id" element={<Devices/>}/>
            </Routes>
        </BrowserRouter>
    );
}

export default App;
