import React, {useEffect, useState} from 'react';
import {Button, Form, Input, Modal, Popconfirm, Space, Table} from 'antd';
import {supabase} from './supabase.js';
import {useNavigate} from 'react-router-dom';
import {TABLE_APPLICATIONS} from './supabase';


const ApplicationsFormModal = ({form, open, onOk, onCancel,}) => {
    return (
        <Modal
            open={open}
            title='应用'
            okText='提交'
            cancelText='取消'
            okButtonProps={{autoFocus: true}}
            onCancel={onCancel}
            destroyOnClose
            onOk={onOk}
        >
            <Form layout='vertical' form={form}>
                <Form.Item name='id' label='应用标识' hidden={true}>
                    <Input/>
                </Form.Item>
                <Form.Item name='name' label='应用名称'>
                    <Input/>
                </Form.Item>
                <Form.Item name='info' label='应用信息'>
                    <Input/>
                </Form.Item>
            </Form>
        </Modal>
    );
};

const ActionBar = ({record, onEditClick, onChannelClick, onDeleteClick}) => {
    return (
        <Space size='middle'>
            <Button onClick={() => onEditClick(record)}>编辑</Button>
            <Button onClick={() => onChannelClick(record)}>渠道</Button>
            <Popconfirm
                title='删除应用'
                description='您确定要删除该应用吗？'
                onConfirm={() => onDeleteClick(record)}
                onCancel={null}
                okText='是'
                cancelText='否'
            >
                <Button danger>删除</Button>
            </Popconfirm>
        </Space>
    );
}

const Applications = () => {
    const navigate = useNavigate();
    const [form] = Form.useForm();
    const [data, setData] = useState([]);
    const [open, setOpen] = useState(false);
    const [modal, contextHolder] = Modal.useModal();

    useEffect(() => {
        fetchData();
    }, []);
    const fetchData = async () => {
        const {data, error} = await supabase.from(TABLE_APPLICATIONS).select();
        if (error) {
            console.log(error)
            modal.error({
                title: error.message,
                content: error.hint,
            });
            return
        }
        if (data) {
            console.log(data)
            data.sort((a, b) => a.id - b.id)
            setData(data);
        }
    };
    const onBackClick = () => {
        navigate(-1)
    }
    const onNewClick = () => {
        console.log('onNewClick')
        form.resetFields()
        setOpen(true)
    }
    const onEditClick = (record) => {
        console.log('onEditClick')
        console.log(record)
        form.setFieldsValue(record)
        setOpen(true);
    }
    const onCancelClick = async () => {
        console.log('onCancelClick')
        form.resetFields()
        setOpen(false)
    }
    const onOkClick = async () => {
        console.log('onOkClick')
        const value = form.getFieldsValue()
        console.log(value)
        const {data, error} = await supabase.from(TABLE_APPLICATIONS).upsert(value)
        if (error) {
            console.log(error)
            modal.error({
                title: error.message,
                content: error.hint,
            });
            return
        }
        if (data) {
            console.log(data)
        }

        form.resetFields()
        setOpen(false)
        fetchData()
    }
    const onChannelClick = (record) => {
        console.log('onChannelClick')
        console.log(record)
        const application_id = record['id']
        console.log(application_id)
        navigate('/channels/' + application_id)
    }
    const onDeleteClick = async (record) => {
        console.log('onDeleteClick')
        console.log(record)
        const application_id = record['id']
        console.log(application_id)
        const {data, error} = await supabase.from(TABLE_APPLICATIONS).delete().eq('id', application_id);
        if (error) {
            console.log(error)
            modal.error({
                title: error.message,
                content: error.hint,
            });
            return
        }
        if (data) {
            console.log(data)
        }
        fetchData()
    }
    const columns = [
        {
            title: '应用标识',
            key: 'id',
            dataIndex: 'id',
            align: 'center',
            width: 100,
        },
        {
            title: '应用名称',
            key: 'name',
            dataIndex: 'name',
            align: 'center',
            width: 200,

        },
        {
            title: '应用信息',
            key: 'info',
            align: 'center',
            dataIndex: 'info',
        },
        {
            title: '操作',
            key: 'action',
            align: 'center',
            width: 300,

            render: (_, record) => (
                <ActionBar
                    record={record}
                    onEditClick={onEditClick}
                    onChannelClick={onChannelClick}
                    onDeleteClick={onDeleteClick}
                />
            ),
        }
    ]

    return (
        <div>
            <div style={{margin: '20px'}}>
                <div style={{display: 'flex', justifyContent: 'space-between'}}>
                    <Button type='default' variant='default' onClick={onBackClick}
                            style={{marginBottom: '20px'}}>返回上一页</Button>
                    <header>应用管理</header>
                    <div style={{display: 'flex', alignItems: 'center'}}>
                        <Button type='default' variant='default' onClick={fetchData}
                                style={{marginBottom: '20px', marginRight: '10px'}}>刷新</Button>
                        <Button type='primary' variant='primary' onClick={onNewClick}
                                style={{marginBottom: '20px'}}>添加</Button>
                    </div>
                </div>
                <ApplicationsFormModal
                    form={form}
                    open={open}
                    onOk={onOkClick}
                    onCancel={onCancelClick}
                />
                {contextHolder}
                <Table columns={columns} dataSource={data} rowKey={(record) => record['id']}/>
            </div>
        </div>
    );
}

export default Applications;
