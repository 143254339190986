import React, {useEffect, useState} from 'react';
import {Button, Form, Input, Modal, Popconfirm, Select, Space, Table} from 'antd';
import {supabase} from './supabase.js';
import {useNavigate, useParams} from 'react-router-dom';
import {TABLE_CHANNELS, TABLE_PACKAGES} from './supabase';


const ApplicationsFormModal = ({form, options, open, onOk, onCancel,}) => {
    return (
        <Modal
            open={open}
            title='应用'
            okText='提交'
            cancelText='取消'
            okButtonProps={{autoFocus: true}}
            onCancel={onCancel}
            destroyOnClose
            onOk={onOk}
        >
            <Form layout='vertical' form={form}>
                <Form.Item name='id' label='渠道标识' hidden={true}>
                    <Input/>
                </Form.Item>
                <Form.Item name='application_id' label='应用标识' hidden={true}>
                    <Input/>
                </Form.Item>
                <Form.Item name='name' label='渠道名称'>
                    <Input/>
                </Form.Item>
                <Form.Item name='info' label='渠道信息'>
                    <Input/>
                </Form.Item>
                <Form.Item name='latest_package_id' label='最新包标识'>
                    <Select options={options} fieldNames={{label: 'id', value: 'id'}} allowClear/>
                </Form.Item>
            </Form>
        </Modal>
    );
};


const Channels = () => {
    const navigate = useNavigate();
    const [form] = Form.useForm();
    const [data, setData] = useState([]);
    const [open, setOpen] = useState(false);
    const [options, setOptions] = useState([]);
    const [modal, contextHolder] = Modal.useModal();

    const params = useParams()
    const application_id = params['application_id'];
    form.setFieldsValue(params)

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        const {data, error} = await supabase.from(TABLE_CHANNELS)
            .select()
            .eq('application_id', application_id);
        if (error) {
            console.log(error)
            modal.error({
                title: error.message,
                content: error.hint,
            });
            return
        }
        if (data) {
            console.log(data)
            data.sort((a, b) => a.id - b.id)
            setData(data);
        }
    };
    const onBackClick = () => {
        navigate(-1)
    }
    const onNewClick = () => {
        console.log('onNewClick')
        form.resetFields()
        setOptions([])
        setOpen(true)
    }
    const onEditClick = (record) => {
        console.log('onEditClick')
        console.log(record)
        form.setFieldsValue(record)
        const channel_id = record['id']
        setOptions([])

        supabase.from(TABLE_PACKAGES).select().eq('channel_id', channel_id).then((data) => {
            console.log('typeof data')
            console.log(data.data)
            setOptions(data.data)
        })

        setOpen(true);
    }
    const onCancelClick = async () => {
        console.log('onCancelClick')
        form.resetFields()
        setOptions([])
        setOpen(false)
    }
    const onOkClick = async () => {
        console.log('onOkClick')
        const value = form.getFieldsValue()
        console.log(value)
        // value['latest_package_id'] = null;
        if (value["latest_package_id"] === undefined) {
            value["latest_package_id"] = null
        }
        console.log(value)
        const {data, error} = await supabase.from(TABLE_CHANNELS)
            .upsert(value)
        if (error) {
            console.log(error)
            modal.error({
                title: error.message,
                content: error.hint,
            });
            return
        }
        if (data) {
            console.log(data)
        }
        setOpen(false)
        form.resetFields()
        fetchData()
    }
    const onChannelClick = (record) => {
        console.log('onChannelClick')
        console.log(record)
        const channel_id = record['id']
        console.log(channel_id)
        navigate('/packages/' + channel_id)
    }
    const onDeviceClick = (record) => {
        console.log('onChannelClick')
        console.log(record)
        const channel_id = record['id']
        console.log(channel_id)
        navigate('/devices/' + channel_id)
    }
    const onDeleteClick = async (record) => {
        console.log('onDeleteClick')
        console.log(record)
        const channel_id = record['id']
        console.log(channel_id)
        const {data, error} = await supabase.from(TABLE_CHANNELS).delete().eq('id', channel_id);
        if (error) {
            console.log(error)
            modal.error({
                title: error.message,
                content: error.hint,
            });
            return
        }
        if (data) {
            console.log(data)
        }
        fetchData()
    }
    const columns = [
        {
            title: '渠道标识',
            key: 'id',
            dataIndex: 'id',
            align: 'center',
            width: 100,
        },
        {
            title: '渠道名称',
            key: 'name',
            dataIndex: 'name',
            align: 'center',
            width: 200,
        },
        {
            title: '渠道信息',
            key: 'info',
            dataIndex: 'info',
        },
        {
            title: '渠道最新版本包',
            key: 'latest_package_id',
            dataIndex: 'latest_package_id',
            align: 'center',
            width: 200,
        },
        {
            title: '操作',
            key: 'action',
            align: 'center',
            width: 300,
            render: (_, record) => (
                <Space size='middle'>
                    <Button onClick={() => onEditClick(record)}>编辑</Button>
                    <Button onClick={() => onChannelClick(record)}>包</Button>
                    <Button onClick={() => onDeviceClick(record)}>设备</Button>
                    <Popconfirm
                        title='删除渠道'
                        description='您确定要删除该渠道吗？'
                        onConfirm={() => onDeleteClick(record)}
                        onCancel={null}
                        okText='是'
                        cancelText='否'
                    >
                        <Button danger>删除</Button>
                    </Popconfirm>
                </Space>
            ),
        }
    ]

    return (
        <div>
            <div style={{margin: '20px'}}>
                <div style={{display: 'flex', justifyContent: 'space-between'}}>
                    <Button type='default' variant='default' onClick={onBackClick}
                            style={{marginBottom: '20px'}}>返回上一页</Button>
                    <header>渠道管理</header>
                    <div style={{display: 'flex', alignItems: 'center'}}>
                        <Button type='default' variant='default' onClick={fetchData}
                                style={{marginBottom: '20px', marginRight: '10px'}}>刷新</Button>
                        <Button type='primary' variant='primary' onClick={onNewClick}
                                style={{marginBottom: '20px'}}>添加</Button>
                    </div>
                </div>
                <ApplicationsFormModal
                    form={form}
                    open={open}
                    onOk={onOkClick}
                    onCancel={onCancelClick}
                    options={options}
                />
                {contextHolder}
                <Table columns={columns} dataSource={data}/>
            </div>
        </div>
    );
}

export default Channels;
