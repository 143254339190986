import {createClient} from "@supabase/supabase-js";


export const SUPABASE_URL = "https://dingli.westwale.com:8443";
export const SUPABASE_KEY = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.ewogICJyb2xlIjogImFub24iLAogICJpc3MiOiAic3VwYWJhc2UiLAogICJpYXQiOiAxNzA4NjE3NjAwLAogICJleHAiOiAxODY2NDcwNDAwCn0.Kt0nbYHLZ33ZA_BXOO2Ql12vQWQ9SGYrpU5H3hKRQd8";
// export const SUPABASE_URL = "https://ybnilcavhdmcisvotgrv.supabase.co";
// export const SUPABASE_KEY = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6InlibmlsY2F2aGRtY2lzdm90Z3J2Iiwicm9sZSI6ImFub24iLCJpYXQiOjE3MDk1MjM3MTksImV4cCI6MjAyNTA5OTcxOX0.mLClclnSTqAXoT1JFGRo8fWHrT4BrG1XzXNQrYfhTZU";
export const SUPABASE_SCHEMA = 'update';
export const BUCKET_ID = 'update'
export const TABLE_APPLICATIONS = 'applications'
export const TABLE_PACKAGES = 'packages'
export const TABLE_CHANNELS = 'channels'
export const TABLE_DEVICES = 'devices'

export const supabase = createClient(
    SUPABASE_URL,
    SUPABASE_KEY,
    {
        db: {schema: SUPABASE_SCHEMA}
    },
)
